import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Hui Kee Fishball" link="https://fishball.com.hk" bg="#e7b06b" mdxType="ProjectCard">
The official website of Hui Kee Fishball. #gatsby #netlify-cms
    </ProjectCard>
    <ProjectCard title="New Life City Church" link="http://www.nlcitychurch.org.hk" bg="#b5bf45" mdxType="ProjectCard">
New Life City Church website redesigned.
    </ProjectCard>
    <ProjectCard title="RCCPC" link="http://phil.arts.cuhk.edu.hk/rccpc" bg="#330333" mdxType="ProjectCard">
The official website of Research Centre for Chinese Philosophy and Culture. #wordpress
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      