import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Ng Wing Tat, David`}</h1>
    <p>{`I am a front-end developer who speaks design.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      